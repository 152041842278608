import { Component, OnInit, Input } from '@angular/core';
import { Params, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../services/ApiService';
import { Subscription } from 'rxjs/Subscription';
import { GlobalUtils } from './../../../services/global-utils';
import { globalService } from './../../../services/global.service';
import { StorageService } from './../../../services/storage.service';

declare var swal: any;

@Component({
    selector: 'secondary-drop-route-change',
    templateUrl: './secondary-drop-route-change.html',
    styles: [`
        .fa-close{
            float: right;
            color: #808080;
            margin: -30px 0px 8px 23px;
            padding: 0 5px 5px 3px;
            cursor: pointer;
            position: absolute;
            z-index: 9999;
            right: 7%
      }
      .mt-2{
        margin-top:30px;
      }
      .btn{
        border-radius:0px
      }
    `]
})
export class secondaryDropRouteChangeComponent {

    schoolRoutes: any;
    routeName: any;
    routeMonday: any = []
    routeTuesday: any = []
    routeWednesday: any = []
    routeThursday: any = []
    routeFriday: any = []
    routeSaturday: any = []
    sectionOption: any;
    routetags: any = [];
    id: any;
    studentList: any = {};
    studentInfo: any;
    public selectedID: string = ''

    routeInfo: any = {};
    routeIdDetails: any = [];
    locationInfo: any = {};
    dropInfo: any = {};
    private selectedRouteReturn: any = [];

    public pickupInfo: any = [];


    private selectedRoute: any;


    public studentDetails: any = [];
    public studentInformation: any = [];
    public locationDetails: any = [];
    public ArrayDetails: any = [];
    public route1Info: any = [];
    public route2Info: any = [];
    public showDroppickupList: boolean;
    public updatedStudentInfo: any = [];

    public emailAlert: boolean = true;
    public smsAlert: boolean;
    public appAlert: boolean;
    public callAlert: boolean;
    public drouppoint: boolean;
    public pickuppoint: boolean;
    public is_edit: boolean = true;

    public rifdChangeInput: any;
    public showpickupList: boolean;
    public linguastical: boolean = false;
    public classAndSessions: any = {};


    public formError: any = {};//validator instance
    public errorMesages: any = [];//validator instance
    OrgrouteAssignDetails: any;
    public routeAdhoc: any = {};
    secondaryRouteInfoSearch: any

    private apiToken: any = localStorage.getItem("scbToken");
    private routeSub: Subscription;
    public checkrouteType: string;
    adhocMultiRouteInfo: any = {};
    selectedRouteName: any;
    selectedRouteType: any;
    selectedPointname: any;
    selectedPointId: any;
    adhocRoutes: any = [{}];
    routeReturnETA: any;
    constructor(public router: Router,
        public apiService: ApiService,
        private route: ActivatedRoute,
        private globalUtils: GlobalUtils,
        private globalService: globalService,
        private storage: StorageService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });

        this.getRoutes();

    }






    ngOnInit(): void {
        this.routeSub = this.route.params.subscribe((params: Params): void => {
            this.viewStudent(params['id']);
        });
        this.viewOrgDetail()
    }



    selectedPickupRouteIds: string[] = [];
    selectedDropRouteIds: string | null = null;




    addStudent() {

        this.router.navigate(['/add-student-details', {}]);
    }
    backToDashboard() {

        //this.redirect('/student-dashboard',{});
        this.router.navigate(['/student-dashboard', {}]);
    }

    tableView() {
        this.router.navigate(['/school-students-list', {}]);
    }

    rfidUpload() {
        this.router.navigate(['/rfid-upload']);
    }

    excelUploadfunction() {
        this.router.navigate(['/student-excel-upload', {}]);
    }

    UpdateStudentExcelUpload() {
        this.router.navigate(['/studentInfo-update-upload', {}]);
    }



    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }



    getRoutes() {

        let parent: any = this;
        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        };
        this.apiService.getRoutes(routeObj).then(response => {
            var parent: any = this;
            if (response) {
                this.schoolRoutes = response.response;
            };
        }).catch(error => { });
    }



    populateRouteStoppagesReturn(adhoc: any = {}) {
        // console.log(selectedRoute2);
        var stoppageObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                "filter": {
                    routeId: adhoc['routeId'],
                }
            }
        }
        this.apiService.getStoppageDetails(stoppageObj).then(response => {
            //this.studentReturnRoutePickup = response.response.pickuppoints;
            adhoc['pickuppoints'] = response.response.pickuppoints;
            this.adhocPickupSelection(adhoc['pickupId'], adhoc);
        });
    }

    estimateEtaReturnRoute(studentDropLocation: any, studId: any, stuName: any, record: any) { ///console.log(studentDropLocation);

        let parent = this;
        if (studentDropLocation == 'adddropPoint') {
            swal({
                title: 'Are you sure?',
                text: 'Do you want to add new drop point to selected route',
                icon: 'warning',
                buttons: [
                    'No, cancel it!',
                    'Yes, I am sure!'
                ],
                dangerMode: false,
            }).then(function (isConfirm: any) {
                if (isConfirm) {
                    parent.storage.set({
                        'sudentID': studId,
                        'studentName': stuName,
                        'navigationFrom': 'editStudent',
                        'navigateType': 'routeList',
                        'selectedRouteId': parent.selectedRouteReturn
                    });

                    //localStorage.setItem();

                    parent.router.navigate(['/school-routes-list']);
                }

            })
        } else {

            let point = record['studentReturnRoutePickup']
            if (point && point[0]) {
                this.routeReturnETA = point[0].expectedTime;
                this.selectedPointname = point[0].pickuppoint;
                this.selectedPointId = point[0].pickupId;

            }

        }
    }


    viewRouteAdhoc() {
        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    memberId: this.studentInfo.memberId,
                }
            }
        }
        this.apiService.viewAdhocMultiRoutes(routeObj).then(response => {
            let result = response;
            this.adhocRoutes = result;
            if (this.adhocRoutes.length == 0) this.adhocRoutes = [{}];
            else {
                for (let adhoc of this.adhocRoutes) {
                    this.setSelectedRoute2([adhoc], adhoc);
                }
            }
            // this.adhocMultiRouteInfo = result
        }).catch(error => { });
    }


    viewStudent(id: any) {

        this.apiService.viewMember({
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    pageJump: 0
                },
                filter: {
                    memberId: id,
                    memberinfo: true
                }
            }
        }).then(response => {
            let result = response;
            if (result.status == "success") {
                if (result.response != '' && result.response != undefined && result.response != null) {
                    this.studentList = result.response; // here response is API output

                    this.smsAlert = this.studentList[0].smsAlert;
                    this.appAlert = this.studentList[0].appAlert;
                    this.emailAlert = this.studentList[0].emailAlert;
                    this.callAlert = this.studentList[0].callAlert;

                    this.studentInfo = this.studentList[0];
                    localStorage.setItem('previousRfid', this.studentInfo.rfid);
                    this.locationInfo = this.studentInfo.memberInfo;


                    if (this.studentInfo.hasOwnProperty('memberInfo') && this.locationInfo.length > 0 && this.locationInfo[0] != undefined && this.locationInfo[0] != '') {
                        for (let info of this.locationInfo) {
                            this.dropInfo = this.locationInfo.filter(function (index: any) { return index.routeType == 'drop' });

                            this.pickupInfo = this.locationInfo.filter(function (index: any) { return index.routeId });
                        }
                    }
                    this.viewRouteAdhoc()


                }
            }
        })
            .catch(error => {
                console.log('No routes or pickup point Assigned', error)
            });


    }



    find_route_data(routeId: string, data: any = []) {
        return data.filter((item: any) => item['routeId'] == routeId)
    }

    submit() {
        let result = [];
        for (let item of this.adhocRoutes) {
            const existingdata = this.find_route_data(item['routeId'], result);
            // if (existingdata.length > 0) continue;
            result.push({
                routeId: item['routeId'],
                routeName: item['routeName'],
                routeType: item['routeType'],
                pickupId: item['selectedPickupPointId'],
                eta: item['selectedPickupPointETA'],
                pickupPointName: item['selectedPickupPointName'],


            })
        }

        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    memberId: this.studentInfo.memberId,
                    adhocRoutes: result
                }
            }
        }
        this.apiService.createMultiRouteAdhoc(routeObj).then(response => {
            if (response.status == "success") {
                swal("Success", "Route Information Updated Successfully", "success", {
                    timer: 2000
                });
                this.viewRouteAdhoc();
            }
        }).catch(error => { });
    }



    remove_adhoc(index: number) {
        this.adhocRoutes = this.adhocRoutes.filter((item: any, i: number) => i != index);
    }



    passIdOnClik(id: any) {
        this.router.navigate(['/edit-student-details/', id]);
    }

    // routeassign

    viewOrgDetail() {
        this.apiService.getCurrentOrgDetails({
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {},
            }
        }).then(response => {
            let result = response;
            const classandsection: any = {};
            if (result.response && result.response.reports && result.response.reports.routeAdhoc
                && result.response.reports.routeAdhoc.routeAdhoc
            ) {
                this.routeAdhoc = result.response.reports.routeAdhoc.routeAdhoc;
            }
        })
    }
    setSelectedRoute2(selectedRoute2: any, adhoc: any = {}) {

        function swal_error() {
            adhoc['route_selection_reset'] = true;
            adhoc['pickuppoints'] = [];
            swal({
                title: 'Warning',
                text: "Selected route type same as pick route type.",
                icon: 'error'
            });
            setTimeout(() => {
                adhoc['route_selection_reset'] = undefined;
                adhoc['routeName'] = '';

                adhoc = {}
            }, 500)
        }

        if (selectedRoute2.length > 0) {
            if (((selectedRoute2[0] && selectedRoute2[0].routeId) === (this.pickupInfo[0] && this.pickupInfo[0].routeId))) {

                swal_error();
            } else if ((selectedRoute2[0] && selectedRoute2[0].routeId) === (this.dropInfo[0] && this.dropInfo[0].routeId)) {
                swal_error();
            }
            else {
                adhoc['routeId'] = selectedRoute2[0].routeId;
                adhoc['routeName'] = selectedRoute2[0].routeName;
                adhoc['routeType'] = selectedRoute2[0].routeType;


                // this.selectedRouteReturn = selectedRoute2[0].routeId;
                // this.selectedRouteName = selectedRoute2[0].routeName;
                // this.selectedRouteType = selectedRoute2[0].routeType;

                this.populateRouteStoppagesReturn(adhoc);
            }

        } else {
            this.selectedRouteReturn = '';
        }
    }

    adhocPickupSelection(pickupId: any, adhoc: any = {}) {
        if (pickupId == undefined || pickupId == '') return;
        adhoc['selectedPickupPointId'] = pickupId;
        const selectedpoint = adhoc['pickuppoints'].filter((item: any) => item['pickupId'] == pickupId);
        adhoc['selectedPickupPointETA'] = selectedpoint[0]['expectedTime']
        adhoc['selectedPickupPointName'] = selectedpoint[0]['pickuppoint']
        adhoc['pickupId'] = pickupId;
        adhoc['eta'] = adhoc['selectedPickupPointETA'];
    }

    addOneMore() {
        this.adhocRoutes.push({})
    }

}


import { Component, OnInit, Input } from '@angular/core';
import { Params, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../services/ApiService';
import { Subscription } from 'rxjs/Subscription';

declare var swal: any;

@Component({
    selector: 'add-student-route-management',
    templateUrl: './route-assign-details.html',
    styles: [`.mt-2{
        margin-top:10px
    
     
    }.btn{
        border-radius:0px;
    } `]
})
export class routeAssignDetailsComponent {

    schoolRoutes: any;
    routeName: any;
    routeMonday: any = []
    routeTuesday: any = []
    routeWednesday: any = []
    routeThursday: any = []
    routeFriday: any = []
    routeSaturday: any = []
    sectionOption: any;
    options = ['', 'Morning', 'Afternoon', 'Evening'];
    routetags: any = [];
    id: any;
    studentList: any = {};
    studentInfo: any;
    public selectedID: string = ''

    routeInfo: any = [];
    routeIdDetails: any = [];
    routeId: string;
    selectedRouteIds: string[] = [];
    secondaryRouteInfo: any = {};
    selectedRouteNames: { [key: string]: { pickup?: string, drop?: string } } = {};
    defaultRouteName: any;
    defaultRouteId: any;
    selectedRouteId: string | null = null;
    // changedDay: string[] = [];
    changedDay: any = [];
    secondaryPickupID: any;
    selectedAdhoc: any = {
        'monday': {
            pickup: {},
            drop: {}
        }, 'tuesday': {
            pickup: {},
            drop: {}
        }, 'wednesday': {
            pickup: {},
            drop: {}
        }, 'thursday': {
            pickup: {},
            drop: {}
        }, 'friday': {
            pickup: {},
            drop: {}
        }, 'saturday': {
            pickup: {},
            drop: {}
        }
    };
    constructor(public router: Router,
        public apiService: ApiService,
        private route: ActivatedRoute,

    ) {

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });

    }
    ngOnInit() {
        this.getRoutes()
        this.selectedRouteNames = {};
        this.routeSub = this.route.params.subscribe((params: Params): void => {
            this.viewStudent(params['id']);
        });

    }






    addStudent() {

        this.router.navigate(['/add-student-details', {}]);
    }
    backToDashboard() {

        //this.redirect('/student-dashboard',{});
        this.router.navigate(['/student-dashboard', {}]);
    }

    tableView() {
        this.router.navigate(['/school-students-list', {}]);
    }

    rfidUpload() {
        this.router.navigate(['/rfid-upload']);
    }

    excelUploadfunction() {
        this.router.navigate(['/student-excel-upload', {}]);
    }

    UpdateStudentExcelUpload() {
        this.router.navigate(['/studentInfo-update-upload', {}]);
    }
    private routeSub: Subscription;

    getRoutes() {
        let parent: any = this;
        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),

            }
        };

        this.apiService.getRoutes(routeObj).then(response => {
            var parent: any = this;
            if (response) {
                this.schoolRoutes = response.response;

                for (let roteData of this.schoolRoutes) {
                    let routeTags = roteData['tags']
                    this.routetags = routeTags
                }

            };
            this.routeSub = this.route.params.subscribe((params: Params): void => {
                this.viewStudent(params['id']);
            });
        }).catch(error => { });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }


    viewStudent(id: any) {
        this.apiService.viewMember({
            data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                    pageJump: 0
                },
                filter: {
                    memberId: id,
                    memberinfo: true
                }
            }
        }).then(response => {
            let result = response;
            if (result.status == "success") {
                if (result.response != '' && result.response != undefined && result.response != null) {
                    this.studentList = result.response[0];
                    let dropData = this.studentList.memberInfo
                    for (let routeItem of dropData) {
                        let defaultRouteName = routeItem
                        this.defaultRouteName = defaultRouteName.routeName
                        this.defaultRouteId = defaultRouteName.routeId

                    }

                    this.viewRouteAdhoc()

                }
            }
        })
            .catch(error => {
                console.log('No routes or pickup point Assigned', error)
            });
    }

    viewRouteAdhoc() {
        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    memberId: this.studentList.memberId,

                }
            }
        }
        this.apiService.viewRouteAdhoc(routeObj).then(response => {
            let result = response;
            this.routeInfo = result['possibleAdhoc'];
            this.selectedAdhoc = result['selectedAdhoc'];

            // if (result.status == "success") {
            //     if (result.response != '' && result.response != undefined && result.response != null) {
            //         this.routeInfo = result
            //         this.secondaryRouteInfo = result.response.secondaryRouteInfo
            //         this.secondaryPickupID = result.response.secondaryRouteInfo.pickupId
            //         this.matchRouteIdsWithSchoolRoutes();

            //     }
            // }
        }).catch(error => { });

    }

    adHocChanges(day: string, type: string, event: any) {

        if (this.selectedAdhoc === undefined) this.selectedAdhoc = {};
        if (this.selectedAdhoc[day] === undefined) this.selectedAdhoc[day] = {};
        if (this.selectedAdhoc[day][type] === undefined) this.selectedAdhoc[day][type] = {};
        this.selectedAdhoc[day][type] = JSON.parse(event.target.value);
    }

    // { possibeAdhoc: {monday:{pickup:[], drop:[]}}, selectedAdhoc: {} }


    passIdOnClik(id: any) {

        this.router.navigate(['/edit-student-details/', id]);

    }




    createRouteAdhoc() {



        var routeObj = {
            data: {
                key: localStorage.getItem('scbToken'),
                form: {
                    memberId: this.studentList.memberId,
                    selectedAdhoc: this.selectedAdhoc,


                }
            }
        };

        this.apiService.createRouteAdhoc(routeObj).then((response: any) => {
            if (response.status == "success") {
                swal("Success", "Route Information Updated Successfully", "success", {
                    timer: 2000
                });
            }

        }).catch(error => { });
    }
}





